"use client";

// Components
import Layout from "@/components/Layout";

// Containers
import HomeContainer from "@/containers/Home";

const Home = () => {
  return (
    <Layout>
      <HomeContainer />
    </Layout>
  );
};

export default Home;
